'use client';
export { Link, withRouter, ReactPixel }

import React from 'react'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { getUrl, getFullUrl, Link as L } from '#/renderer/Link'

const Link = L

function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const withRouter = (Component) => (props) =>  {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const search = searchParams.toString()
  const state = search && Object.fromEntries(searchParams)
  const location = {
    pathname,
    search,
    href: getFullUrl(pathname, search, state),
    state,
  }
  const history = {
    push(url) {
      router.push(getUrl(url))
    },
    replace(url) {
      router.replace(getUrl(url), { scroll: false })
    },
    goBack() {
      router.back()
    },
    location,
  }
  return (
    <Component {...props} location={location} history={history} />
  )
}

const ReactPixel = {
  async init() {
    if (!window.fbq) {
      await wait(3000);
    }
  },
  async pageView() {
    await this.init();
    window.fbq('track', 'PageView');
  },
  async track(title, data) {
    await this.init();
    window.fbq('track', title, data);
  },
};
